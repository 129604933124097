.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  background-color: #eee;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.content {
  margin-right: 40px;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.content:hover{
  background-color: #36da88;
}

.mainComponent{
  background-color: #ddd;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
